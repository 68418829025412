import React from 'react'
import * as styles from './button.module.scss'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'
import Responsive from '_components/Common/Responsive/Responsive'
import { Button as RSButton } from '@goorm-dev/components'

export const Button: React.FC<
  ChildrenClassNameProps & {
    active: boolean
    onClick: Function
  }
> = ({ className, children, active, onClick }) => {
  return (
    <Responsive
      def={
        <RSButton
          className={className}
          color="link"
          active={active}
          onClick={onClick}
          size={'md'}
        >
          {children}
        </RSButton>
      }
      // sm={(
      // 	<RSButton
      // 		className={className}
      // 		color='link'
      // 		active={active}
      // 		onClick={onClick}
      // 		size={'sm'}
      // 	>
      // 		{children}
      // 	</RSButton>
      // )}
    />
  )
}
