import React from 'react'
import cx from 'classnames'
import * as styles from './Header.module.scss'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'

export const Header: React.FC<ChildrenClassNameProps> = ({ children }) => {
  const headerClasses = cx([styles.Header, 'text-center', 'text-white'])

  return <h1 className={headerClasses}>{children}</h1>
}

export const HeaderWithContents: React.FC<
  ChildrenClassNameProps & { header: string | JSX.Element | React.FC }
> = ({ header, children }) => {
  const headerClasses = cx([
    styles.HeaderWithContents__textHead,
    'text-center',
    'text-white',
  ])
  const contentsClasses = cx([
    styles.HeaderWithContents__textSub,
    'text-center',
  ])

  return (
    <div className={styles.HeaderWithContents}>
      <h1 className={headerClasses}>{header}</h1>
      <p className={contentsClasses}>{children}</p>
    </div>
  )
}
