import React from 'react'
import cx from 'classnames'
import * as styles from './IntroSection.module.scss'
import Fade from '_components/Common/Fade/Fade'

import INTRO_MOVIE from '_images/Common/common-ecosystem.png'
import INTRO_MOVIE_STATIC from '_images/Common/common-ecosystem-static.png'
import { Col, Container, Row } from '@goorm-dev/components'
import Responsive from '_components/Common/Responsive/Responsive'
import ScrollHint from '_components/Common/ScrollHint/ScrollHint'

const Intro: React.FC = function () {
  return (
    <section className={styles.Intro}>
      <article>
        <Fade
          distance="50px"
          durationFunc="ease-out"
          duration="0.5s"
          freezeOnceVisible
        >
          <div className={styles.Header}>
            <h1 className={styles.Header__textHead}>개발자 성장 중심 생태계</h1>
            {/* <p className={styles.Header__textSub}>Development Ecosystem as a Service</p> */}
          </div>
          <div className={styles.Description}>
            <p
              className={cx([
                styles.Description__paragraph,
                styles.Description__paragraphDef,
              ])}
            >
              모두가 개발자가 된다는 비전으로
              <br />
              언제 어디서나 AI·SW 개발을 배우고 원하는 결과를 구현할 수 있도록
              <br />
              지식과 경험, 환경과 도구, 컴퓨팅 파워를 제공합니다.
            </p>
            <p
              className={cx([
                styles.Description__paragraph,
                styles.Description__paragraphXs,
              ])}
            >
              모두가 개발자가 된다는 비전으로
              <br />
              언제 어디서나 AI·SW 개발을 배우고
              <br />
              원하는 결과를 구현할 수 있도록
              <br />
              지식과 경험, 환경과 도구, 컴퓨팅 파워를 제공합니다.
            </p>
          </div>
        </Fade>
        <Container fluid={'lg'}>
          <Responsive
            def={
              <Row className={'justify-content-center'}>
                <Col xs="7">
                  <img
                    src={INTRO_MOVIE}
                    className={cx(styles.Intro__video, 'w-100')}
                  />
                </Col>
              </Row>
            }
            xl={
              <Row className={'justify-content-center'}>
                <Col xs="8">
                  <img
                    src={INTRO_MOVIE}
                    className={cx(styles.Intro__video, 'w-100')}
                  />
                </Col>
              </Row>
            }
            lg={
              <Row className={'justify-content-center'}>
                <Col xs="10">
                  <img
                    src={INTRO_MOVIE}
                    className={cx(styles.Intro__video, 'w-100')}
                  />
                </Col>
              </Row>
            }
            md={
              <Row className={'justify-content-center'}>
                <Col xs="12">
                  <img
                    src={INTRO_MOVIE}
                    className={cx(styles.Intro__video, 'w-100')}
                  />
                </Col>
              </Row>
            }
            sm={
              <Row className={'justify-content-center'}>
                <Col xs="12">
                  <img
                    src={INTRO_MOVIE_STATIC}
                    className={cx(styles.Intro__video, 'w-100')}
                  />
                </Col>
              </Row>
            }
          />
        </Container>
      </article>
      <ScrollHint />
    </section>
  )
}

export default Intro
