import React, { useState } from 'react'
import cx from 'classnames'
import * as styles from './TechnologySection.module.scss'
import Fade from '_components/Common/Fade/Fade'
import Responsive from '_components/Common/Responsive/Responsive'
import { Container, Badge } from '@goorm-dev/components'
// import { CarouselIndicators, CarouselItem, Carousel } from 'reactstrap';
import {
  CarouselIndicators,
  CarouselItem,
  Carousel as RSCarousel,
} from 'reactstrap'
import { Carousel } from '_components/Common/Carousel/Carousel'
import { HeaderWithContents } from '_components/Service/Header/Header'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'

const items = [
  [
    {
      badge: '특허 제10-2131000호',
      desc: '복수의 컴파일 주체를 이용한 코딩 시스템의 효율을 높이는 방법 및 장치',
    },
    {
      badge: '특허 제10-2200181호',
      desc: '책과 연동되어 간편한 코딩 실습을 위한 정보를 제공하는 방법 및 장치',
    },
  ],
  [
    {
      badge: '특허 제10-2201619호',
      desc: '게임형 온라인 코딩 교육 제공 방법',
    },
    {
      badge: '특허 제10-2206216호',
      desc: '코딩 및 프로그래밍 교육 컨텐츠 플랫폼 제공 방법',
    },
  ],
  [
    {
      badge: '특허 제10-2221228호',
      desc: '코딩 테스트 및 채점 알고리즘 제공 방법',
    },
    {
      badge: '특허 제10-2228117호',
      desc: '온라인 이력 데이터 기반 프로그래머 역량 평가 방법 및 장치',
    },
  ],
]

const PatentCarousel: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: any) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`patent-${item[0].badge}-${index}`}
      >
        <Patent
          key={`patentCarousel-${item[0].badge}-${index}`}
          className={cx(['mb-3', styles.PatentsInCarousel])}
          badge={item[0].badge}
        >
          {item[0].desc}
        </Patent>
        <Patent
          key={`patentCarousel-${item[1].badge}-${index}`}
          className={cx([styles.PatentsInCarousel])}
          badge={item[1].badge}
        >
          {item[1].desc}
        </Patent>
      </CarouselItem>
    )
  })

  return (
    <RSCarousel activeIndex={activeIndex} next={next} previous={previous}>
      <div style={{ bottom: '-19.5rem', position: 'relative' }}>
        <CarouselIndicators
          key={`patent-${activeIndex}`}
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
      </div>
      {slides}
    </RSCarousel>
  )
}

const Patent: React.FC<
  ChildrenClassNameProps & {
    badge: string
  }
> = ({ children, className, badge }) => {
  return (
    <div
      className={cx([className, styles.Patents__item, 'd-flex', 'flex-column'])}
    >
      <div className={'mb-2'}>
        <Badge size="sm" color="light" className={cx([styles.Patents__badge])}>
          {badge}
        </Badge>
      </div>
      <p>{children}</p>
    </div>
  )
}

const TechnologySection: React.FC = function () {
  const slides = items.map((item, index) => {
    return (
      <div key={item[0].badge + index}>
        <Patent
          key={item[0].badge + index}
          className={cx(['mb-3', styles.PatentsInCarousel])}
          badge={item[0].badge}
        >
          {item[0].desc}
        </Patent>
        <Patent
          key={item[1].badge + index}
          className={cx([styles.PatentsInCarousel])}
          badge={item[1].badge}
        >
          {item[1].desc}
        </Patent>
      </div>
    )
  })

  return (
    <section className={styles.TechnologySection}>
      <Fade
        distance="50px"
        durationFunc="ease-out"
        duration="0.5s"
        threshold={0.4}
        freezeOnceVisible
      >
        <Responsive
          def={
            <HeaderWithContents header="특허로 보는 구름 기술력">
              국내 최고 수준의 클라우드 서비스 개발·운영 경험을 활용해
              <br />
              창의적인 아이디어를 실현하고, 압도적인 기술력을 누적해오고
              있습니다.
            </HeaderWithContents>
          }
          sm={
            <HeaderWithContents header="특허로 보는 구름 기술력">
              국내 최고 수준의 클라우드 서비스 개발·운영 경험을
              <br />
              활용해 창의적인 아이디어를 실현하고,
              <br />
              압도적인 기술력을 누적해오고 있습니다.
            </HeaderWithContents>
          }
        />
      </Fade>
      <Responsive
        def={
          <>
            <Container fluid="lg" className={cx([styles.Patents])}>
              <Fade
                threshold={0.1}
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.TechnologySection__fade}
                duration="0.5s"
              >
                <Patent badge="특허 제10-2131000호">
                  복수의 컴파일 주체를 이용한 코딩 시스템의 효율을 높이는 방법
                  및 장치
                </Patent>
              </Fade>
              <Fade
                threshold={0.1}
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.TechnologySection__fade}
                duration="0.5s"
                delay="0.2s"
              >
                <Patent badge="특허 제10-2200181호">
                  책과 연동되어 간편한 코딩 실습을 위한 정보를 제공하는 방법 및
                  장치
                </Patent>
              </Fade>
              <Fade
                threshold={0.1}
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.TechnologySection__fade}
                duration="0.5s"
                delay="0.4s"
              >
                <Patent badge="특허 제10-2201619호">
                  게임형 온라인 코딩 교육 제공 방법
                </Patent>
              </Fade>
              <Fade
                threshold={0.1}
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.TechnologySection__fade}
                duration="0.5s"
                delay="0.6s"
              >
                <Patent badge="특허 제10-2206216호">
                  코딩 및 프로그래밍 교육 컨텐츠 플랫폼 제공 방법
                </Patent>
              </Fade>
              <Fade
                threshold={0.1}
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.TechnologySection__fade}
                duration="0.5s"
                delay="0.8s"
              >
                <Patent badge="특허 제10-2221228호">
                  코딩 테스트 및 채점 알고리즘 제공 방법
                </Patent>
              </Fade>
              <Fade
                threshold={0.1}
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.TechnologySection__fade}
                duration="0.5s"
                delay="1.0s"
              >
                <Patent badge="특허 제10-2228117호">
                  온라인 이력 데이터 기반 프로그래머 역량 평가 방법 및 장치
                </Patent>
              </Fade>
            </Container>
          </>
        }
        sm={
          <Container fluid="lg">
            <PatentCarousel />
            {/* <Carousel> */}
          </Container>
        }
      />
    </section>
  )
}

export default TechnologySection
