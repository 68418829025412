import React, { CSSProperties } from 'react'
import cx from 'classnames'
import * as styles from './Card.module.scss'
import { Badge, Button, Col, Row } from '@goorm-dev/components'
import Responsive from '_components/Common/Responsive/Responsive'

const redirect = (url: string) => (window.location.href = url)
const openWindow = (url: string) => window.open(url)

type ServiceCardProps = {
  image: string
  logo: string
  header: string
  description: string
  button: {
    text: string
    to: string
  }
  className?: string
  badges?: string[]
  bgcolor?: string
}

export const AiswCard: React.FC<ServiceCardProps> = ({
  className,
  image,
  logo,
  header,
  description,
  button,
  badges,
  bgcolor,
}) => {
  const cardClasses = cx(styles.NewCard, className)
  const imageWrapperStyles: CSSProperties = {
    backgroundImage: `URL(${image})`,
    backgroundColor: `${bgcolor}`,
  }
  const CardImage = () => (
    <div
      className={cx(styles.NewCard__image, styles.NewCard__imageLeft)}
      style={imageWrapperStyles}
    />
  )

  return (
    <div className={cardClasses}>
      <Row>
        <Responsive
          def={
            <Col xs="7">
              <CardImage />
            </Col>
          }
          lg={
            <Col xs="6">
              <CardImage />
            </Col>
          }
        />
        <Col>
          <div
            className={cx(styles.NewCard__content, styles.NewCard__contentLeft)}
          >
            <div className={'mb-3'}>
              <img src={logo} alt={'aisw-logo'} />
              {badges?.map(badge => (
                <Badge className={'ml-2'} size={'md'} color={'light'} pill>
                  {badge}
                </Badge>
              ))}
            </div>
            <h2 className={cx(styles.NewCard__header, 'mb-2')}>{header}</h2>
            <p className={cx(styles.NewCard__descriptionAisw, 'mb-4')}>
              {description}
            </p>
            <Button
              color="light"
              size="lg"
              onClick={() => {
                openWindow(button.to)
              }}
            >
              {button.text}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const AssessmentSkillTopCard: React.FC<ServiceCardProps> = ({
  className,
  image,
  logo,
  header,
  description,
  button,
  badges,
  bgcolor,
}) => {
  const cardClasses = cx(styles.NewCard, className)
  const imageWrapperStyles: CSSProperties = {
    backgroundImage: `URL(${image})`,
    backgroundColor: `${bgcolor}`,
  }
  const CardImage = () => (
    <div
      className={cx(styles.NewCard__image, styles.NewCard__imageRight)}
      style={imageWrapperStyles}
    />
  )

  return (
    <div className={cardClasses}>
      <Row>
        <Col>
          <div
            className={cx(
              styles.NewCard__content,
              styles.NewCard__contentRight,
            )}
          >
            <div className={'mb-3'}>
              <img src={logo} alt={'aisw-logo'} />
              {badges?.map(badge => (
                <Badge className={'ml-2'} size={'md'} color={'light'} pill>
                  {badge}
                </Badge>
              ))}
            </div>
            <h2 className={cx(styles.NewCard__header, 'mb-2')}>{header}</h2>
            <p className={cx(styles.NewCard__description, 'mb-4')}>
              {description}
            </p>
            <Button
              color="light"
              size="lg"
              onClick={() => {
                openWindow(button.to)
              }}
            >
              {button.text}
            </Button>
          </div>
        </Col>
        <Responsive
          def={
            <Col xs="7">
              <CardImage />
            </Col>
          }
          lg={
            <Col xs="6">
              <CardImage />
            </Col>
          }
        />
      </Row>
    </div>
  )
}

export const AssessmentSkillBottomCard: React.FC<
  ServiceCardProps & {
    isDevthObserviewCard?: boolean
  }
> = ({
  className,
  image,
  logo,
  header,
  description,
  button,
  badges,
  bgcolor,
  isDevthObserviewCard,
}) => {
  const cardClasses = cx(styles.NewCard, className)
  const imageClasses = cx(
    isDevthObserviewCard && styles.NewCard__imageObserview,
  )
  const imageWrapperStyles: CSSProperties = {
    backgroundColor: `${bgcolor}`,
  }

  return (
    <div className={cardClasses}>
      <div
        className={cx(styles.NewCard__content, styles.NewCard__contentBottom)}
      >
        <div className={'mb-3'}>
          <img src={logo} alt={'aisw-logo'} className={imageClasses} />
          {badges?.map((badge, index) => (
            <Badge
              className={'ml-2'}
              size={'md'}
              color={'light'}
              key={`${badge}-${index}`}
              pill
            >
              {badge}
            </Badge>
          ))}
        </div>
        <h2 className={cx(styles.NewCard__header, 'mb-2')}>{header}</h2>
        <p className={cx(styles.NewCard__descriptionAssessmentBottom, 'mb-4')}>
          {description}
        </p>
        <Button
          color="light"
          size="lg"
          onClick={() => {
            openWindow(button.to)
          }}
        >
          {button.text}
        </Button>
      </div>
      <div>
        <img
          src={image}
          alt={image}
          className={cx(styles.NewCard__image, styles.NewCard__imageBottom)}
          style={imageWrapperStyles}
        />
      </div>
    </div>
  )
}

export const IdeaCard: React.FC<
  ServiceCardProps & {
    isDevthObserviewCard?: boolean
  }
> = ({
  className,
  image,
  logo,
  header,
  description,
  button,
  badges,
  bgcolor,
  isDevthObserviewCard,
}) => {
  const cardClasses = cx(styles.NewCard, className)
  const imageClasses = cx(
    isDevthObserviewCard && styles.NewCard__imageObserview,
  )
  const imageWrapperStyles: CSSProperties = {
    backgroundColor: `${bgcolor}`,
  }

  return (
    <div className={cardClasses}>
      <div
        className={cx(styles.NewCard__content, styles.NewCard__contentBottom)}
      >
        <div className={'mb-3'}>
          <img src={logo} alt={'aisw-logo'} className={imageClasses} />
          {badges?.map((badge, index) => (
            <Badge
              className={'ml-2'}
              size={'md'}
              color={'light'}
              key={`${badge}-${index}`}
              pill
            >
              {badge}
            </Badge>
          ))}
        </div>
        <h2 className={cx(styles.NewCard__header, 'mb-2')}>{header}</h2>
        <p className={cx(styles.NewCard__description, 'mb-4')}>{description}</p>
        <Button
          color="light"
          size="lg"
          onClick={() => {
            openWindow(button.to)
          }}
        >
          {button.text}
        </Button>
      </div>
      <div>
        <img
          src={image}
          alt={image}
          className={cx(styles.NewCard__image, styles.NewCard__imageBottom)}
          style={imageWrapperStyles}
        />
      </div>
    </div>
  )
}

export const MobileCard: React.FC<ServiceCardProps> = ({
  className,
  image,
  logo,
  header,
  description,
  button,
  badges,
  bgcolor,
}) => {
  const cardClasses = cx(styles.MobileCard, className)
  const imageWrapperStyles: CSSProperties = {
    backgroundColor: `${bgcolor}`,
  }

  return (
    <div className={cardClasses}>
      <div>
        <img
          src={image}
          alt={image}
          className={styles.MobileCard__image}
          style={imageWrapperStyles}
          loading={'lazy'}
        />
      </div>
      <div className={styles.MobileCard__content}>
        <div className={cx('mb-3', styles.MobileCard__logo)}>
          <img src={logo} alt={'mobile-logo'} />
          {badges?.map((badge, index) => (
            <Badge
              className={'ml-2'}
              size={'md'}
              color={'light'}
              key={`${badge}-${index}`}
              pill
            >
              {badge}
            </Badge>
          ))}
        </div>
        <h2 className={cx(styles.MobileCard__header, 'mb-2')}>{header}</h2>
        <p className={cx(styles.MobileCard__description, 'mb-4')}>
          {description}
        </p>
        <Button
          color="light"
          size="lg"
          onClick={() => {
            openWindow(button.to)
          }}
        >
          {button.text}
        </Button>
      </div>
    </div>
  )
}
