import React from 'react'
import cx from 'classnames'
import { Container } from '@goorm-dev/components'

import { Header } from '_components/Service/Header/Header'
import { List, Item } from '_components/Service/List/List'
import { Button as ServiceCardButton } from '_components/Service/Button/Button'
import {
  AssessmentSkillBottomCard,
  AssessmentSkillTopCard,
  MobileCard,
} from '_components/Service/Card/Card'
import Fade from '_components/Common/Fade/Fade'
import Responsive from '_components/Common/Responsive/Responsive'

import DevthImage from '_images/Service/assessment-skill-devth.png'
import LevelImage from '_images/Service/assessment-skill-level.png'
import ObserviewImage from '_images/Service/assessment-skill-obserview.png'
import GoormDevthLogo from '_images/Service/goorm-devth-logo.svg'
import GoormDevthObserviewLogo from '_images/Service/goorm-devth-obserview-logo.svg'
import GoormLevelLogo from '_images/Service/goorm-level-logo.svg'

import * as styles from './AssessmentSkillSection.module.scss'

const MobileCardSection: React.FC<{ className: string }> = ({ className }) => {
  type ButtonType = 'goormDevth' | 'goormLEVEL' | 'goormDEVTH OBSERVIEW'
  const [clickedButton, setClickedButton] =
    React.useState<ButtonType>('goormDevth')

  const SelectedCard = () =>
    ({
      goormDevth: (
        <MobileCard
          image={DevthImage}
          logo={GoormDevthLogo}
          header={'개발자 채용 코딩 테스트,\n구름DEVTH'}
          description={
            '변화하는 개발자 채용 트렌드에 맞춘 코딩 테스트 플랫폼입니다. \n안정성과 공정성을 최우선으로 지원자의 역량을 정확하게 검증합니다.'
          }
          button={{
            text: '구름DEVTH 바로가기',
            to: 'https://devth.goorm.io',
          }}
          bgcolor={'#5413BF'}
          badges={['기업 대상']}
        />
      ),
      goormLEVEL: (
        <MobileCard
          image={LevelImage}
          logo={GoormLevelLogo}
          header={'알고리즘 문제 풀이,\n구름LEVEL'}
          description={
            '누구나 프로그래밍 문제를 만들고 공유할 수 있습니다.\n다양한 난이도의 알고리즘 문제를 스스로 해결하며 컴퓨팅 사고력을 기를 수 있습니다.'
          }
          button={{
            text: '구름LEVEL 바로가기',
            to: 'https://level.goorm.io',
          }}
          bgcolor={'#8B72F0'}
        />
      ),
      'goormDEVTH OBSERVIEW': (
        <MobileCard
          image={ObserviewImage}
          logo={GoormDevthObserviewLogo}
          header={'실시간 화상 감독,\n구름DEVTH 옵저뷰'}
          description={
            '온라인 시험의 부정행위 사각지대를 최소화한 실시간 화상 감독 서비스입니다. 기업, 학교, 기관의 다양한 시험 운영 및 감독을 지원합니다.'
          }
          button={{
            text: '구름DEVTH 옵저뷰 바로가기',
            to: 'https://obserview.goorm.io',
          }}
          bgcolor={'#8247FF'}
        />
      ),
    }[clickedButton])

  return (
    <Fade
      distance="50px"
      durationFunc="ease-out"
      duration="0.5s"
      threshold={0.2}
      freezeOnceVisible
      className={styles.AssessmentSkill__fade}
    >
      <div
        className={cx([
          styles.AssessmentSkill__switch,
          'text-center',
          'd-flex',
          'justify-content-center',
        ])}
      >
        <ServiceCardButton
          active={clickedButton === 'goormDevth'}
          onClick={() => setClickedButton('goormDevth')}
        >
          구름DEVTH
        </ServiceCardButton>
        <ServiceCardButton
          active={clickedButton === 'goormLEVEL'}
          onClick={() => setClickedButton('goormLEVEL')}
        >
          구름LEVEL
        </ServiceCardButton>
        <ServiceCardButton
          active={clickedButton === 'goormDEVTH OBSERVIEW'}
          onClick={() => setClickedButton('goormDEVTH OBSERVIEW')}
        >
          구름DEVTH 옵저뷰
        </ServiceCardButton>
      </div>
      <section className={className}>
        <SelectedCard />
      </section>
    </Fade>
  )
}

const PcCardSection: React.FC<{ className: string }> = ({ className }) => {
  return (
    <section className={className}>
      <Fade
        distance="50px"
        durationFunc="ease-out"
        threshold={0.4}
        freezeOnceVisible
        className={cx([
          styles.AssessmentSkill__fadeDevth,
          styles.AssessmentSkill__fadeWrapper,
        ])}
      >
        <AssessmentSkillTopCard
          image={DevthImage}
          logo={GoormDevthLogo}
          badges={['기업 대상']}
          header={'개발자 채용 코딩 테스트,\n구름DEVTH'}
          description={
            '변화하는 개발자 채용 트렌드에 맞춘 코딩 테스트 플랫폼입니다. 안정성과 공정성을 최우선으로 지원자의 역량을 정확하게 검증합니다.'
          }
          button={{
            text: '구름DEVTH 바로가기',
            to: 'https://devth.goorm.io',
          }}
          bgcolor={'#5413BF'}
        />
      </Fade>
      <Fade
        distance="50px"
        durationFunc="ease-out"
        threshold={0.4}
        freezeOnceVisible
        className={cx([
          styles.AssessmentSkill__fadeLevel,
          styles.AssessmentSkill__fadeWrapper,
          styles.AssessmentSkill__fade,
        ])}
      >
        <AssessmentSkillBottomCard
          image={LevelImage}
          logo={GoormLevelLogo}
          header={'알고리즘 문제 풀이,\n구름LEVEL'}
          description={
            '누구나 프로그래밍 문제를 만들고 공유할 수 있습니다. 다양한 난이도의 알고리즘 문제를 스스로 해결하며 컴퓨팅 사고력을 기를 수 있습니다.'
          }
          button={{
            text: '구름LEVEL 바로가기',
            to: 'https://level.goorm.io',
          }}
          bgcolor={'#8B72F0'}
        />
      </Fade>
      <Fade
        distance="50px"
        durationFunc="ease-out"
        threshold={0.4}
        freezeOnceVisible
        className={cx([
          styles.AssessmentSkill__fadeObserview,
          styles.AssessmentSkill__fadeWrapper,
          styles.AssessmentSkill__fade,
        ])}
      >
        <AssessmentSkillBottomCard
          image={ObserviewImage}
          logo={GoormDevthObserviewLogo}
          header={'실시간 화상 감독,\n구름DEVTH 옵저뷰'}
          badges={['기업 대상']}
          description={
            '온라인 시험의 부정행위 사각지대를 최소화한 실시간 화상 감독 서비스입니다. 기업, 학교, 기관의 다양한 시험 운영 및 감독을 지원합니다.'
          }
          button={{
            text: '구름DEVTH 옵저뷰 바로가기',
            to: 'https://obserview.goorm.io',
          }}
          bgcolor={'#8247FF'}
          isDevthObserviewCard
        />
      </Fade>
    </section>
  )
}

const AssessmentSkill: React.FC = function () {
  const cardSectionClasses = cx([styles.AssessmentSkill__cardSection])
  const mobileSectionClasses = cx([
    'justify-content-center',
    'd-flex',
    'flex-column',
    'mb-3',
  ])

  return (
    <section className={styles.AssessmentSkill}>
      <Container fluid={'lg'}>
        <Fade
          distance="50px"
          durationFunc="ease-out"
          duration="0.5s"
          threshold={0.4}
          freezeOnceVisible
          className={styles.AssessmentSkill__fade}
        >
          <Header>실력을 정확하게 평가받고</Header>
        </Fade>
        <Responsive
          def={<PcCardSection className={cardSectionClasses} />}
          md={<MobileCardSection className={mobileSectionClasses} />}
        />

        <Responsive
          def={
            <List className={styles.AssessmentSkill__list}>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                duration="0.5s"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="인재 채용 기회">
                  효율적인 평가 프로세스를 기반으로 지원자의 역량을 정확하게
                  검증해 인재 채용 기회를 제공합니다.
                </Item>
              </Fade>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                duration="0.5s"
                delay="0.3s"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="맞춤형 문제 은행">
                  문제 제작이 어렵다면 문제 은행을 이용해 보세요. 다양한 유형과
                  난이도로 준비되어 있습니다.
                </Item>
              </Fade>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                duration="0.5s"
                delay="0.6s"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="커리어 성장 기회">
                  기업과 함께 다양한 코딩 대회를 운영하여 개발자 커리어 성장의
                  기회를 제공합니다.
                </Item>
              </Fade>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                duration="0.5s"
                delay="0.9s"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="코딩 테스트 실전 대비">
                  지원자는 다양한 유형과 난이도 문제를 스스로 풀어 보며 문제
                  해결 능력을 키울 수 있습니다.
                </Item>
              </Fade>
            </List>
          }
          md={
            <List className={styles.AssessmentSkill__list}>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="인재 채용 기회">
                  효율적인 평가 프로세스를 기반으로 지원자의 역량을 정확하게
                  검증해 인재 채용 기회를 제공합니다.
                </Item>
              </Fade>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="맞춤형 문제 은행">
                  문제 제작이 어렵다면 문제 은행을 이용해 보세요. 다양한 유형과
                  난이도로 준비되어 있습니다.
                </Item>
              </Fade>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="커리어 성장 기회">
                  기업과 함께 다양한 코딩 대회를 운영하여 개발자 커리어 성장의
                  기회를 제공합니다.
                </Item>
              </Fade>
              <Fade
                distance="50px"
                durationFunc="ease-out"
                freezeOnceVisible
                className={styles.AssessmentSkill__fade}
              >
                <Item header="코딩 테스트 실전 대비">
                  지원자는 다양한 유형과 난이도 문제를 스스로 풀어 보며 문제
                  해결 능력을 키울 수 있습니다.
                </Item>
              </Fade>
            </List>
          }
        />
      </Container>
    </section>
  )
}

export default AssessmentSkill
