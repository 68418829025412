import React from 'react'
import cx from 'classnames'
import * as styles from './IdeaSection.module.scss'
import { Container, Button } from '@goorm-dev/components'
import Fade from '_components/Common/Fade/Fade'
import Responsive from '_components/Common/Responsive/Responsive'
import { IdeaCard, MobileCard } from '_components/Service/Card/Card'
import { Header } from '_components/Service/Header/Header'
import { List, Item } from '_components/Service/List/List'

import GoormIdeImage from '_images/Service/idea-goorm-ide.png'
import GoormIdeLogo from '_images/Service/goorm-ide-logo.svg'

const MobileCardSection: React.FC = () => {
  return (
    <Fade
      distance="50px"
      durationFunc="ease-out"
      duration="0.5s"
      threshold={0.2}
      freezeOnceVisible
      className={cx([styles.IdeaSection__fade, 'mb-3'])}
    >
      <MobileCard
        image={GoormIdeImage}
        logo={GoormIdeLogo}
        header={'클라우드 통합 개발 환경,\n구름IDE'}
        description={
          '몇 번의 클릭만으로 개발 환경을 구축할 수 있습니다. 클라우드 자원을 자유롭게 활용해 아이디어를 구현해 보세요.'
        }
        button={{
          text: '구름IDE 바로가기',
          to: 'https://ide.goorm.io',
        }}
        bgcolor={'#A131E5'}
      />
    </Fade>
  )
}

const DesktopCardSection: React.FC = () => {
  return (
    <Fade
      distance="50px"
      durationFunc="ease-out"
      duration="0.5s"
      threshold={0.2}
      freezeOnceVisible
      className={styles.IdeaSection__fade}
    >
      <IdeaCard
        image={GoormIdeImage}
        logo={GoormIdeLogo}
        header={'클라우드 통합 개발 환경,\n구름IDE'}
        description={
          '몇 번의 클릭만으로 개발 환경을 구축할 수 있습니다. 클라우드 자원을 자유롭게 활용해 아이디어를 구현해 보세요. '
        }
        button={{
          text: '구름IDE 바로가기',
          to: 'https://ide.goorm.io',
        }}
        bgcolor={'#A131E5'}
      />
    </Fade>
  )
}

const Idea: React.FC = function ({}) {
  return (
    <section className={styles.IdeaSection}>
      <Container fluid={'lg'}>
        <Fade
          distance="50px"
          durationFunc="ease-out"
          duration="0.5s"
          threshold={0.4}
          freezeOnceVisible
          className={styles.IdeaSection__fade}
        >
          <Header>아이디어를 마음껏 펼치세요</Header>
        </Fade>
        <div
          className={cx([
            styles.IdeaSection__wrapper,
            'justify-content-between',
          ])}
        >
          <Responsive def={<DesktopCardSection />} md={<MobileCardSection />} />
          <List className={styles.IdeaSection__list}>
            <Fade
              distance="50px"
              durationFunc="ease-out"
              freezeOnceVisible
              className={styles.IdeaSection__fadeText}
            >
              <Item header="새로운 개발 경험">
                반복되는 일을 자동화하고 복잡한 작업을 쉽게 처리할 수 있도록
                지원하여 최고의 개발 경험을 제공합니다.
              </Item>
            </Fade>
            <Fade
              distance="50px"
              durationFunc="ease-out"
              freezeOnceVisible
              className={styles.IdeaSection__fadeText}
            >
              <Item header="생산성 극대화">
                더 빠르고 접근하기 쉽도록, 최신 클라우드 기술을 활용해 강력한
                기능을 지원합니다.
              </Item>
            </Fade>
            <Fade
              distance="50px"
              durationFunc="ease-out"
              freezeOnceVisible
              className={styles.IdeaSection__fadeText}
            >
              <Item header="비즈니스 성공을 돕습니다">
                기업의 디지털 트랜스포메이션, 데브옵스, 개발의 어려움을 줄이고
                고객 비즈니스 성공을 돕습니다.
              </Item>
            </Fade>
          </List>
        </div>
      </Container>
    </section>
  )
}

export default Idea
