// extracted by mini-css-extract-plugin
export var Card = "Card-module--Card--ba864";
export var CardVertical = "Card-module--Card--vertical--8dda5";
export var CardVertical__image = "Card-module--Card--vertical__image--fdbe8";
export var Card__content = "Card-module--Card__content--a5255";
export var Card__image = "Card-module--Card__image--092c0";
export var Card__images = "Card-module--Card__images--cda3f";
export var MobileCard = "Card-module--MobileCard--d3a79";
export var MobileCard__content = "Card-module--MobileCard__content--2c245";
export var MobileCard__description = "Card-module--MobileCard__description--000c2";
export var MobileCard__header = "Card-module--MobileCard__header--e75a4";
export var MobileCard__image = "Card-module--MobileCard__image--12d8c";
export var MobileCard__logo = "Card-module--MobileCard__logo--7083c";
export var NewCard = "Card-module--NewCard--b0b08";
export var NewCard__content = "Card-module--NewCard__content--184a9";
export var NewCard__contentBottom = "Card-module--NewCard__content--bottom--54506";
export var NewCard__contentLeft = "Card-module--NewCard__content--left--ce78d";
export var NewCard__contentRight = "Card-module--NewCard__content--right--3d0f2";
export var NewCard__description = "Card-module--NewCard__description--fc09a";
export var NewCard__descriptionAisw = "Card-module--NewCard__description--aisw--0019e";
export var NewCard__descriptionAssessmentBottom = "Card-module--NewCard__description--assessmentBottom--14305";
export var NewCard__header = "Card-module--NewCard__header--eb4ad";
export var NewCard__image = "Card-module--NewCard__image--5ad14";
export var NewCard__imageBottom = "Card-module--NewCard__image--bottom--37318";
export var NewCard__imageLeft = "Card-module--NewCard__image--left--27b51";
export var NewCard__imageMobile = "Card-module--NewCard__image--mobile--e8096";
export var NewCard__imageObserview = "Card-module--NewCard__image--obserview--496aa";
export var NewCard__imageRight = "Card-module--NewCard__image--right--c0d16";
export var cardFadeIn = "Card-module--cardFadeIn--68cfe";