import React from 'react'
import cx from 'classnames'
import * as styles from './LearnAISWSection.module.scss'
import { Container, Button } from '@goorm-dev/components'
import { Button as ServiceCardButton } from '_components/Service/Button/Button'
import Fade from '_components/Common/Fade/Fade'
import Responsive from '_components/Common/Responsive/Responsive'
import { AiswCard, MobileCard } from '_components/Service/Card/Card'
import { Header } from '_components/Service/Header/Header'
import { List, Item } from '_components/Service/List/List'

import EduPublicImage from '../../../images/Service/learn-aisw-edu-public.png'
import EduChannelImage from '../../../images/Service/learn-aisw-edu-channel.png'
import GoormEduLogo from '../../../images/Service/goorm-edu-logo.svg'
import GoormEduChannelLogo from '../../../images/Service/goorm-edu-channel-logo.svg'

const MobileCardSection: React.FC<{ className: string }> = ({ className }) => {
  type ButtonType = 'edu' | 'channel'
  const [clickedButton, setClickedButton] = React.useState<ButtonType>('edu')
  const SelectedCard = () =>
    ({
      edu: (
        <MobileCard
          image={EduPublicImage}
          logo={GoormEduLogo}
          header={'SW 교육 플랫폼,\n구름EDU'}
          description={
            '개발자가 되고 싶은 분들을 위한 SW 교육 플랫폼입니다. 누구나 양질의 SW 교육 콘텐츠를 이용하고 성장할 수 있도록 배움의 기회를 제공합니다.'
          }
          button={{
            text: '구름EDU 바로가기',
            to: 'https://edu.goorm.io',
          }}
          bgcolor={'#4374F3'}
        />
      ),
      channel: (
        <MobileCard
          image={EduChannelImage}
          logo={GoormEduChannelLogo}
          header={'SW 교육 공간,\n구름EDU 채널'}
          description={
            '이론과 실습, 시험 도구를 한데 모은 올인원 온라인 SW 교육 공간입니다. SW 교육이 필요한 고객은 독립적인 공간인 채널에서 효율적으로 교육하고 관리할 수 있습니다. '
          }
          button={{
            text: '구름EDU 채널 바로가기',
            to: 'https://channel.goorm.io',
          }}
          bgcolor={'#491EF6'}
          badges={['기업 대상']}
        />
      ),
    }[clickedButton])

  return (
    <Fade
      distance="50px"
      durationFunc="ease-out"
      duration="0.5s"
      threshold={0.2}
      freezeOnceVisible
      className={styles.LearnAISW__fade}
    >
      <div
        className={cx([
          styles.LearnAISW__switch,
          'text-center',
          'd-flex',
          'justify-content-center',
        ])}
      >
        <ServiceCardButton
          active={clickedButton === 'edu'}
          onClick={() => setClickedButton('edu')}
        >
          구름EDU
        </ServiceCardButton>
        <ServiceCardButton
          active={clickedButton === 'channel'}
          onClick={() => setClickedButton('channel')}
        >
          구름EDU 채널
        </ServiceCardButton>
      </div>
      <section className={className}>
        <SelectedCard />
      </section>
    </Fade>
  )
}

const PcCardSection: React.FC<{ className: string }> = ({ className }) => {
  return (
    <div>
      <section className={className}>
        <Fade
          distance="50px"
          durationFunc="ease-out"
          duration="0.5s"
          threshold={0.2}
          freezeOnceVisible
          className={styles.LearnAISW__fade}
        >
          <AiswCard
            image={EduPublicImage}
            logo={GoormEduLogo}
            header={'SW 교육 플랫폼,\n구름EDU'}
            description={
              '개발자가 되고 싶은 분들을 위한 SW 교육 플랫폼입니다. 누구나 양질의 SW 교육 콘텐츠를 이용하고 성장할 수 있도록 배움의 기회를 제공합니다.'
            }
            button={{
              text: '구름EDU 바로가기',
              to: 'https://edu.goorm.io',
            }}
            bgcolor={'#4374F3'}
          />
        </Fade>
        <Fade
          distance="50px"
          durationFunc="ease-out"
          duration="0.5s"
          threshold={0.2}
          freezeOnceVisible
          className={styles.LearnAISW__fade}
        >
          <AiswCard
            image={EduChannelImage}
            logo={GoormEduChannelLogo}
            badges={['기업 대상']}
            header={'SW 교육 공간,\n구름EDU 채널'}
            description={
              '이론과 실습, 시험 도구를 한데 모은 올인원 온라인 SW 교육 공간입니다. SW 교육이 필요한 고객은 독립적인 공간인 채널에서 효율적으로 교육하고 관리할 수 있습니다.'
            }
            button={{
              text: '구름EDU 채널 바로가기',
              to: 'https://channel.goorm.io',
            }}
            bgcolor={'#491EF6'}
          />
        </Fade>
      </section>
    </div>
  )
}

const LearnAISW: React.FC = function () {
  const cardSectionClasses = cx([
    styles.LearnAISW__cardSection,
    'justify-content-center',
    'd-flex',
    'flex-column',
  ])

  return (
    <section className={styles.LearnAISW}>
      <article>
        <Container fluid={'lg'}>
          <Fade
            distance="50px"
            durationFunc="ease-out"
            duration="0.5s"
            threshold={0.4}
            freezeOnceVisible
            className={styles.LearnAISW__fade}
          >
            <Header>AI·SW 지식을 배우고</Header>
          </Fade>

          <Responsive
            def={<PcCardSection className={cardSectionClasses} />}
            md={<MobileCardSection className={cardSectionClasses} />}
          />

          <Responsive
            def={
              <List>
                <Fade
                  distance="50px"
                  durationFunc="ease-out"
                  duration="0.5s"
                  freezeOnceVisible
                  className={styles.LearnAISW__fade}
                >
                  <Item header="누구에게나 열린 기회">
                    타고난 환경에 상관없이 누구나 양질의 SW 교육 콘텐츠를
                    이용하고 배움의 기회를 누립니다.
                  </Item>
                </Fade>
                <Fade
                  distance="50px"
                  durationFunc="ease-out"
                  duration="0.5s"
                  delay={'0.3s'}
                  freezeOnceVisible
                  className={styles.LearnAISW__fade}
                >
                  <Item header="진입장벽을 낮춥니다">
                    개발자 커리어 여정을 처음 시작하는 분들이 겪는 어려움을
                    기술로 해결합니다.
                  </Item>
                </Fade>
                <Fade
                  distance="50px"
                  durationFunc="ease-out"
                  duration="0.5s"
                  delay={'0.6s'}
                  freezeOnceVisible
                  className={styles.LearnAISW__fade}
                >
                  <Item header="상생하는 생태계">
                    플랫폼을 이용하는 강의자, 수강자, 플랫폼 사업자 모두가
                    상생하는 구조를 만듭니다.
                  </Item>
                </Fade>
              </List>
            }
            md={
              <List>
                <Fade
                  distance="50px"
                  durationFunc="ease-out"
                  duration="0.5s"
                  freezeOnceVisible
                  className={styles.LearnAISW__fade}
                >
                  <Item header="누구에게나 열린 기회">
                    타고난 환경에 상관없이 누구나 양질의 SW 교육 콘텐츠를
                    이용하고 배움의 기회를 누립니다.
                  </Item>
                </Fade>
                <Fade
                  distance="50px"
                  durationFunc="ease-out"
                  duration="0.5s"
                  freezeOnceVisible
                  className={styles.LearnAISW__fade}
                >
                  <Item header="진입장벽을 낮춥니다">
                    개발자 커리어 여정을 처음 시작하는 분들이 겪는 어려움을
                    기술로 해결합니다.
                  </Item>
                </Fade>
                <Fade
                  distance="50px"
                  durationFunc="ease-out"
                  duration="0.5s"
                  freezeOnceVisible
                  className={styles.LearnAISW__fade}
                >
                  <Item header="상생하는 생태계">
                    플랫폼을 이용하는 강의자, 수강자, 플랫폼 사업자 모두가
                    상생하는 구조를 만듭니다.
                  </Item>
                </Fade>
              </List>
            }
          />
        </Container>
      </article>
    </section>
  )
}

export default LearnAISW
