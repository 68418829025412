// extracted by mini-css-extract-plugin
export var Description = "IntroSection-module--Description--89630";
export var Description__paragraph = "IntroSection-module--Description__paragraph--419c0";
export var Description__paragraphDef = "IntroSection-module--Description__paragraph--def--fe881";
export var Description__paragraphXs = "IntroSection-module--Description__paragraph--xs--b87e7";
export var Header = "IntroSection-module--Header--11aa0";
export var Header__textHead = "IntroSection-module--Header__text--head--4465a";
export var Header__textSub = "IntroSection-module--Header__text--sub--d4b70";
export var ImageBox__dummyImage = "IntroSection-module--ImageBox__dummyImage--79818";
export var Intro = "IntroSection-module--Intro--79062";
export var cardFadeIn = "IntroSection-module--cardFadeIn--af3db";