import React from 'react'

import PageWrapper from '_components/Common/PageWrapper/PageWrapper'
import Header from '_components/Common/Header/Header'
import RecruitBannerSection from '_containers/Common/RecruitBannerSection/RecruitBannerSection'

import IntroSection from '_containers/Service/IntroSection/IntroSection'
import LearnAISWSection from '_containers/Service/LearnAISWSection/LearnAISWSection'
import AssessmentSkillSection from '_containers/Service/AssessmentSkillSection/AssessmentSkillSection'
import IdeaSection from '_containers/Service/IdeaSection/IdeaSection'
import TechnologySection from '_containers/Service/TechnologySection/TechnologySection'

// NOTE: 개발 이후, 기획 변경우리 url 'platform"으로 변경됨
const ServicePage: React.FC = function () {
  return (
    <PageWrapper Header={<Header color="dark" />} className="bg-black">
      <IntroSection />
      <LearnAISWSection />
      <AssessmentSkillSection />
      <IdeaSection />
      <TechnologySection />
      <RecruitBannerSection />
    </PageWrapper>
  )
}

export default ServicePage
