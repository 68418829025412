import React from 'react'
import cx from 'classnames'
import * as styles from './List.module.scss'
import { ChildrenClassNameProps } from '_types/ChildrenClassNameProps.types'

export const Item: React.FC<{
  className?: string
  children: React.ReactNode
  header: string
}> = ({ className, header, children }) => {
  const itemClasses = cx([
    styles.List__item,
    className,
    'd-flex',
    'flex-fill',
    'justify-content-center',
    'flex-column',
  ])
  const headerClasses = cx([
    styles.List__itemHeader,
    'text-white',
    'text-center',
  ])
  const paragraphClasses = cx([styles.List__itemParagraph, 'text-gray-500'])

  return (
    <div className={itemClasses}>
      <h5 className={headerClasses}>{header}</h5>
      <p className={paragraphClasses}>{children}</p>
    </div>
  )
}

export const List: React.FC<ChildrenClassNameProps> = ({
  className = styles.ListDef,
  children,
}) => {
  const listClasses = cx([styles.List, className])
  return <div className={listClasses}>{children}</div>
}
