// extracted by mini-css-extract-plugin
export var AssessmentSkill = "AssessmentSkillSection-module--AssessmentSkill--baf8a";
export var AssessmentSkill__cardSection = "AssessmentSkillSection-module--AssessmentSkill__cardSection--e47bb";
export var AssessmentSkill__cardVertical = "AssessmentSkillSection-module--AssessmentSkill__card--vertical--eff10";
export var AssessmentSkill__card__paragraph = "AssessmentSkillSection-module--AssessmentSkill__card__paragraph--3196c";
export var AssessmentSkill__fade = "AssessmentSkillSection-module--AssessmentSkill__fade--af3f6";
export var AssessmentSkill__fadeDevth = "AssessmentSkillSection-module--AssessmentSkill__fade--devth--1f214";
export var AssessmentSkill__fadeLevel = "AssessmentSkillSection-module--AssessmentSkill__fade--level--824a5";
export var AssessmentSkill__fadeObserview = "AssessmentSkillSection-module--AssessmentSkill__fade--obserview--13a52";
export var AssessmentSkill__list = "AssessmentSkillSection-module--AssessmentSkill__list--8a7ef";
export var AssessmentSkill__logoDevth = "AssessmentSkillSection-module--AssessmentSkill__logo--devth--e6fcf";
export var AssessmentSkill__logoLevel = "AssessmentSkillSection-module--AssessmentSkill__logo--level--e5ea7";
export var AssessmentSkill__logoObserview = "AssessmentSkillSection-module--AssessmentSkill__logo--obserview--7f9c9";
export var AssessmentSkill__logoWrapper = "AssessmentSkillSection-module--AssessmentSkill__logo--wrapper--7114d";
export var AssessmentSkill__switch = "AssessmentSkillSection-module--AssessmentSkill__switch--bcf25";
export var cardFadeIn = "AssessmentSkillSection-module--cardFadeIn--9a7d5";